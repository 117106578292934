<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Api } from "../../../helpers";
import Swal from "sweetalert2";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
import answerQuestionCard from "../../../components/widgets/card/answerQuestionCard.vue";
export default {
  props: {},
  page: {
    title: "Blog List",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcessVue,
    answerQuestionCard,
  },
  data() {
    return {
      titre: "",
      progress: 0,
      process: false,
      spinner: false,
      allAnswerQuestion: [],
      isAuthError: false,
      authError: "",
      answerQuestionQuiz: {
        name: "",
        isCorrect: false,
      },
      addModal: false,
      secondModal: false,
      title: "Liste des compétitions",
      items: [],
    };
  },
  methods: {
    showAddModal() {
      this.addModal = true;
    },
    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    imgSponsor(event) {
      this.sponsorLogo = event.target.files[0];
      console.log(this.sponsorLogo);
    },
    createAward() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      // if (
      // 	!this.award.description ||
      // 	!this.award.endDate ||
      // 	!this.award.name ||
      // 	!this.award.startDate ||
      // 	!this.award.votePrice ||
      // 	!this.imgPaysage ||
      // 	!this.imgSelect
      // ) {

      // 	this.isAuthError = true
      // 	this.authError = "Veuillez remplir tous les champs"

      // } else {
      this.process = true;
      this.addModal = false;
      this.titre = "Création de la compétition";
      Api.post(
        `/games/rest/answer/create/${this.$route.params.id}`,
        {
          content: this.answerQuestionQuiz.name,
          isCorrect: this.answerQuestionQuiz.isCorrect,
        },
        onUploadProgress
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Compétition créée avec succès", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
      // }
    },
  },

  mounted() {
    // this.process = true
    this.spinner = true;
    this.titre = "Chargement des compétitions";
    const oneQuiz = JSON.parse(localStorage.getItem("quiz"));
    const question = JSON.parse(localStorage.getItem("question"));
    // this.allAnswerQuestion = question.answers;
    this.items.push(
      {
        text: "Quiz",
        href: "/games/categorieQuiz",
      },
      {
        text: oneQuiz.category.name,
        href: `/games/addQuiz/${oneQuiz.category.id}`,
      },
      {
        text: oneQuiz.name,
        href: `/games/quiz/${oneQuiz.id}`,
      },
      {
        text: question.content,
        active: true,
      }
    );
    Api.get(`/games/api/answer/${this.$route.params.id}/question`)
      .then((res) => {
        this.process = false;
        this.spinner = false;
        this.allAnswerQuestion = res.data;
        console.log(res);
      })
      .catch((error) => {
        this.process = false;
        this.spinner = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>
    <div class="row justify-content-end">
      <div style="margin-top: 10px">
        <div
          @click="showAddModal"
          class="btn c2play-primary"
          style="position: relative; left: 76%"
          to="/blog/createArticle"
        >
          <span style="color: #fff"
            ><i class="fas fa-plus font-size-18"></i> {{ $t("addButton") }} une
            option de réponse</span
          >
        </div>
      </div>
    </div>

    <br />

    <div class="row">
      <answerQuestionCard
        v-for="answer in allAnswerQuestion.slice().reverse()"
        :key="answer.id"
        :answer="answer"
      ></answerQuestionCard>

      <div style="text-align: center; font-size: 3em" v-if="spinner == true">
        <b-spinner variant="success" role="status"></b-spinner>
        <span v-if="spinner == false && allAnswerQuestion.length == 0"
          >Aucune option de reponse dans cette question</span
        >
      </div>
    </div>

    <b-modal
      v-model="addModal"
      id="modal-xl"
      size="xl"
      title="Nouvelle option"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>

        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-12">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                v-model="answerQuestionQuiz.name"
                type="text"
                class="form-control"
                placeholder="Entrez le contenu de l'oprion de reponse"
              />
            </div>

            <div class="form-group col-lg-12 mb-3">
              <label for="projectname" class="col-form-label">
                Est-ce la bonne réponse ?</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      v-model="answerQuestionQuiz.isCorrect"
                      name="some-radios1"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      v-model="answerQuestionQuiz.isCorrect"
                      name="some-radios1"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              class="btn c2play-primary"
              @click="createAward"
              style="position: relative; left: 45%"
            >
              Créer
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
