<template>
  <div>
    <!-- Simple card -->
    <div class="col-lg-12 row card p-4">
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="font-size: 1.2em">
          <!-- <router-link
              :to="`/games/addAnswerQuestion/${this.answer.id}`"
              style="color: #303030"
            > -->
          <div>
            <span style="color: #07693a" v-if="answer.isCorrect == true"
              ><i class="mdi mdi-check-circle-outline font-size-18"></i
            ></span>
            {{ answer.content }}
          </div>
          <!-- </router-link> -->
        </div>
        <div>
          <b-button class="c2play-primary">
            <b-dropdown
              class="card-drop"
              variant="white"
              menu-class="dropdown-menu-end"
              right
              toggle-class="p-0"
            >
              <template v-slot:button-content>
                <span style="color: #fff"
                  ><i class="mdi mdi-dots-horizontal font-size-18"></i
                ></span>
              </template>
              <b-dropdown-item @click="showUpdateCat()">{{
                $t("pages.videos.actions.modifier")
              }}</b-dropdown-item>
              <b-dropdown-item @click="confirm()">{{
                $t("pages.videos.actions.supprimer")
              }}</b-dropdown-item>
            </b-dropdown>
          </b-button>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modalUpdateAnswer"
      id="modal-xl"
      size="xl"
      title="Modifier l'option de réponse"
      title-class="font-18"
      hide-footer
    >
      <!-- <div class="card" style="padding: 25px; width: 70%; margin-left: auto; margin-right: auto; border-radius: 15px;"> -->

      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>

        <form>
          <div class="form-group row mb-2">
            <div class="col-lg-12">
              <label for="projectname" class="col-form-label">{{
                $t("pages.videos.formulaire.titre.label")
              }}</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <input
                id="projectname"
                name="projectname"
                v-model="answerQuestionQuiz.name"
                type="text"
                class="form-control"
                placeholder="Entrez le contenu"
              />
            </div>

            <div class="form-group col-lg-12 mb-3">
              <label for="projectname" class="col-form-label">
                Est-ce la bonne réponse ?</label
              ><span style="color: red; font-size: 1.2em">*</span>
              <div class="col-lg-12">
                <div class="display">
                  <div style="margin-right: 20px">
                    <b-form-radio
                      v-model="answerQuestionQuiz.isCorrect"
                      name="some-radios1"
                      value="true"
                      >Oui</b-form-radio
                    >
                  </div>
                  <div>
                    <b-form-radio
                      v-model="answerQuestionQuiz.isCorrect"
                      name="some-radios1"
                      value="false"
                      >Non</b-form-radio
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div class="row">
          <div class="col-lg-12" style="margin-top: 10px">
            <button
              class="btn c2play-primary"
              @click="updateAnswer"
              style="position: relative; left: 45%"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
export default {
  components: {
    loaderProcessVue,
  },
  data() {
    return {
      titre: "",
      progress: 0,
      process: false,
      answerQuestionQuiz: {
        name: "",
        isCorrect: false,
      },
      modalUpdateAnswer: false,
    };
  },
  props: {
    answer: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showUpdateCat() {
      this.modalUpdateAnswer = true;
    },

    imgVideoSelect(event) {
      this.imgSelect = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgVideoSelectPaysage(event) {
      this.imgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    updateAnswer() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      this.process = true;
      this.modalUpdateAnswer = false;
      this.titre = "Modification de l'option de reponse";
      Api.put(
        `/games/rest/answer/update/${this.answer.id}`,
        {
          content: this.answerQuestionQuiz.name,
          isCorrect: this.answerQuestionQuiz.isCorrect,
        },
        {onUploadProgress}
      )
        .then((response) => {
          this.process = false;
          Swal.fire(
            "Succes!",
            "Option de reponse modifiée avec succès",
            "success"
          );
          console.log(response);
          // location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
    confirm() {
      Swal.fire({
        title: "Voulez vous vraiment supprimer cette option de reponse ?",
        // text: "You won't be able to delete this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        cancelButtonText: "Annuler",
        confirmButtonText: "Oui",
      }).then((result) => {
        if (result.value) {
          // alert(id)
          this.deleteAnswer();
          // location.reload();
        }
      });
    },
    deleteAnswer() {
      Api.delete(`/games/rest/answer/delete/${this.answer.id}`)
        .then((response) => {
          console.log(response);
          Swal.fire(
            "Deleted!",
            "Option de reponse supprimé avec succès",
            "success"
          );
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    //   console.log(this.question)
    this.answerQuestionQuiz.isCorrect = this.answer.isCorrect;
    this.answerQuestionQuiz.name = this.answer.content;
  },
};
</script>

<style>
.card-img {
  object-fit: cover;
  height: 200px;
  width: 100%;
  border-radius: 9px;
  margin-bottom: 20px;
}
</style>
